import { Avatar, Card, CardContent, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { education } from "../../app-data/education";

export const EducationComponent = (): JSX.Element => {
  const theme = useTheme();
  return (
    <div
      style={{ marginTop: theme.spacing(10), marginBottom: theme.spacing(10) }}
    >
      <Typography
        color="secondary"
        style={{ textAlign: "center", fontWeight: 700 }}
        variant="h4"
      >
        School
      </Typography>

      <div
        style={{
          display: "grid",
          justifyContent: "center",
          marginTop: theme.spacing(4),
        }}
      >
        {education.map((school) => {
          return (
            <Card
              key={JSON.stringify(school)}
              elevation={3}
              style={{
                marginBottom: theme.spacing(3),
                maxWidth: "600px",
              }}
            >
              <div style={{ padding: theme.spacing(1.5) }}>
                <Avatar
                  style={{
                    display: "inline-flex",
                    marginRight: theme.spacing(1),
                  }}
                  alt={school.schoolName}
                  src={school.logoPath}
                />
                <Typography
                  style={{ float: "right" }}
                  display="inline"
                  variant="caption"
                >
                  {school.graduationDate}
                </Typography>
                <div>
                  <Typography display="inline" variant="h5">
                    {school.schoolName}
                  </Typography>
                </div>
              </div>
              <Divider />

              <CardContent>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ fontWeight: 700 }}
                >
                  {school.program}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: school.descriptionHTML }}
                ></Typography>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </div>
  );
};
