import SheridanLogo from "../work-history/images/sheridan-logo.jpg";

interface EducationValues {
  schoolName: string;
  logoPath: string;
  graduationDate: string;
  program: string;
  descriptionHTML: string;
}

export const education: EducationValues[] = [
  {
    schoolName: "Sheridan College",
    logoPath: SheridanLogo,
    graduationDate: "Graduated Dec 2021",
    program: "Honours Bachelor of Computer Science",
    descriptionHTML: `
    <p>I learned software engineering, mobile development, and wireless networking. <br />
     As well as fundamental areas of computer science such as operating systems
      and computer architecture and advanced topics like artificial intelligence
       and theory of computation.</p>`,
  },
];
