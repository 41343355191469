import { ThemeProvider } from "@emotion/react";
import { Theme } from "@mui/material";
import { ThemeProvider as MUIThemeProvider } from "@mui/system";

interface AppThemeProviderProps {
  children: React.ReactNode;
  theme: Theme;
}

export const AppThemeProvider = ({
  children,
  theme,
}: AppThemeProviderProps): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeProvider>
  );
};
