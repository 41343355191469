import { Box, SpeedDial, SpeedDialAction, Typography } from "@mui/material";

import { Dispatch, SetStateAction } from "react";
import LinkedIn from "@mui/icons-material/LinkedIn";
import GitHub from "@mui/icons-material/GitHub";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Resume from "./resume/GeorgeKopti-Resume.pdf";

interface ContactsDialProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ACTIONS = [
  {
    icon: <LinkedIn />,
    name: "Linked In",
    link: "https://www.linkedin.com/in/georgekopti/",
  },
  { icon: <GitHub />, name: "Git Lab", link: "https://gitlab.com/George_K" },
  { icon: <ArticleOutlinedIcon />, name: "Resume", link: "" },
];

export const ContactsDial = ({
  open,
  setOpen,
}: ContactsDialProps): JSX.Element => {
  return (
    <Box
      sx={{
        transform: "translateZ(0px)",
        position: "sticky",
        bottom: 1,
        right: 1,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 35, right: 35 }}
        icon={<InsertLinkIcon style={{ transform: "rotate(-45deg)" }} />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        {ACTIONS.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={
              <Typography style={{ width: "75px" }}>{action.name}</Typography>
            }
            tooltipOpen
            onClick={() => {
              if (action.name !== "Resume") {
                window.open(action.link, "_blank");
                setOpen(false);
              } else {
                const link = document.createElement("a");
                link.href = Resume;
                link.download = "George-Kopti-Resume.pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};
