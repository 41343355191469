interface PersonalInfoValues {
  name: string;
  introBody: string;
}

export const personalInfo: PersonalInfoValues = {
  name: "George Kopti",
  introBody: `I’m a software engineer specializing in building exceptional digital experiences,
     currently working as a junior software engineer at Caribou and building a
      recruitment application to grow health care staff for organizations by more than
       10% in less than 90 days`,
};

//
