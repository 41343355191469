import {
  Chip,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { workHistory } from "../../app-data/work-history";

export const WorkHistoryComponent = (): JSX.Element => {
  const theme = useTheme();

  const mobileScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div style={{ marginTop: theme.spacing(10) }}>
      <Typography
        color="secondary"
        style={{ textAlign: "center", fontWeight: 700 }}
        variant="h4"
      >
        Work History
      </Typography>

      <Stepper
        orientation="vertical"
        style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(10) }}
      >
        {workHistory.map((company) => (
          <Step active key={JSON.stringify(company)}>
            <StepLabel
              icon={
                <img
                  src={company.imagePath}
                  alt="company logo"
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "17px",
                  }}
                />
              }
              optional={
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{ marginTop: theme.spacing(1) }}
                  >
                    {company.technologies.map((tech) => (
                      <Chip
                        key={tech}
                        label={tech}
                        color="primary"
                        variant="outlined"
                        size="small"
                      />
                    ))}
                  </Stack>
                </>
              }
            >
              <Typography color="primary" display="inline">
                {company.companyName}
              </Typography>

              {mobileScreen && (
                <Typography
                  color="text.secondary"
                  display="inline"
                  variant="caption"
                  style={{
                    float: "right",
                    fontStyle: "italic",
                    fontSize: "1em",
                  }}
                >
                  {company.workDuration}
                </Typography>
              )}
            </StepLabel>
            <StepContent>
              <Typography color="text.primary" variant="caption">
                {company.positionName}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body1"
                style={{ padding: theme.spacing(0.5), textAlign: "justify" }}
              >
                {company.description}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
