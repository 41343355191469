import { Container } from "@mui/material";
import React, { useState } from "react";
import { ApplicationBar } from "./components/application-bar";
import { AppThemeProvider } from "./style/theme-provider";
import { ApplicationMainPage } from "./pages/main-page";
import { appTheme } from "./style/app-theme";
import { ContactsDial } from "./components/contacts-dial";
import { AppFooter } from "./components/footer";

const App: React.FC = () => {
  const [dialOpen, setDialOpen] = useState(false);

  return (
    <AppThemeProvider theme={appTheme}>
      {/* This was omited for now might add it back in the future */}
      {/* <Backdrop open={dialOpen} /> */}
      <ApplicationBar />

      <Container
        maxWidth="lg"
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        <ApplicationMainPage />
      </Container>

      <ContactsDial open={dialOpen} setOpen={setDialOpen} />

      <AppFooter />
    </AppThemeProvider>
  );
};

export default App;
