import { createTheme } from "@mui/material";

// Palette colors are taken from https://coolors.co/palette/3d5a80-98c1d9-e0fbfc-ee6c4d-293241

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#3d5a80",
      light: "#98c1d9",
      dark: "#1e2c40",
    },
    secondary: {
      main: "#EE6C4D",
      light: "#f4a694",
      dark: "#8f260d",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});
