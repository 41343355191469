import { WorkHistoryComponent } from "../../components/work-history";
import { IntroductionComponent } from "../../components/introduction-component";
import { ProjectsComponent } from "../../components/projects-component";
import { EducationComponent } from "../../components/education-component";

export const ApplicationMainPage = (): JSX.Element => {
  return (
    <>
      <IntroductionComponent />

      <section id="projects">
        <ProjectsComponent />
      </section>

      <section id="work-history">
        <WorkHistoryComponent />
      </section>

      <section id="education">
        <EducationComponent />
      </section>
    </>
  );
};
