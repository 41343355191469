import {
  Button,
  Container,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { footerCss } from "./footer-css";
import WelcomeIllustration from "../../illustrations/welcome.png";
import Resume from "../contacts-dial/resume/GeorgeKopti-Resume.pdf";

export const AppFooter = (): JSX.Element => {
  const theme = useTheme();

  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <style>{footerCss}</style>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use
            href="#gentle-wave"
            x="48"
            y="0"
            fill={theme.palette.secondary.light}
          />

          <use
            href="#gentle-wave"
            x="48"
            y="3"
            fill={theme.palette.secondary.main}
          />
          <use
            href="#gentle-wave"
            x="48"
            y="5"
            fill={theme.palette.primary.light}
          />
          <use
            href="#gentle-wave"
            x="48"
            y="7"
            fill={theme.palette.primary.main}
          />
        </g>
      </svg>

      <div
        style={{
          background: theme.palette.primary.main,
          height: "300px",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: mobileScreen ? "auto" : "auto 1% auto 30%",
              justifyItems: "center",
              paddingTop: theme.spacing(6),
              color: "#fff",
            }}
          >
            <div>
              <Typography
                style={{
                  textAlign: mobileScreen ? "center" : undefined,
                  color: theme.palette.secondary.light,
                  fontSize: "1.3em",
                }}
              >
                What’s Next?
              </Typography>
              <div style={{ textAlign: "center", marginTop: theme.spacing(2) }}>
                <Typography variant="h5">Get In Touch</Typography>
                <Typography variant="body1" maxWidth={300}>
                  My inbox is always open. Whether you have a question or just
                  want to say hi, I’ll try my best to get back to you!
                </Typography>
                <Button
                  style={{
                    marginTop: theme.spacing(2),
                    color: theme.palette.secondary.light,
                  }}
                  variant="outlined"
                  color="secondary"
                  href="mailto:george0kopti@gmail.com?subject=Hey%20George%20let%27s%20connect"
                >
                  Say Hello
                </Button>
                {mobileScreen && (
                  <>
                    <Typography
                      style={{
                        marginTop: theme.spacing(1.5),
                        display: "block",
                      }}
                      variant="caption"
                      color="primary.light"
                    >
                      Designed & Built by George Kopti
                    </Typography>
                  </>
                )}
              </div>
            </div>

            {!mobileScreen && (
              <>
                <Divider
                  orientation="vertical"
                  style={{ background: "#fff", height: "230px" }}
                />

                <div>
                  <Button
                    href={Resume}
                    download="George-Kopti-Resume.pdf"
                    style={{ color: theme.palette.secondary.light }}
                  >
                    Resume
                  </Button>
                  <br />
                  <Button
                    href="https://www.linkedin.com/in/georgekopti/"
                    style={{ color: theme.palette.secondary.light }}
                  >
                    Linked In
                  </Button>
                  <br />
                  <Button
                    style={{ color: theme.palette.secondary.light }}
                    href="mailto:george0kopti@gmail.com?subject=Hey%20George%20let%27s%20connect"
                  >
                    George0kopti@gmail.com
                  </Button>
                  <Typography
                    style={{
                      marginLeft: theme.spacing(1),
                      marginTop: theme.spacing(1),
                      display: "block",
                    }}
                    variant="caption"
                    color="primary.light"
                  >
                    Designed & Built by George Kopti
                  </Typography>
                </div>

                <div style={{ justifySelf: "right" }}>
                  <img
                    style={{ marginRight: theme.spacing(-6) }}
                    src={WelcomeIllustration}
                    alt="Bye Illustration"
                  />
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};
