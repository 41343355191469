import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { personalInfo } from "../../app-data/personal-info";
import introIllustration from "../../illustrations/intro.png";

import "./intro.css";

export const IntroductionComponent = (): JSX.Element => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      // className="animate"
      style={{
        display: mobileScreen ? undefined : "grid",
        gridTemplateColumns: "30% auto",
        marginTop: theme.spacing(mobileScreen ? 5 : 8),
      }}
    >
      {!mobileScreen && (
        <img
          style={{ marginTop: theme.spacing(-4) }}
          src={introIllustration}
          alt="Welcome Illustration"
          width={270}
          height={270}
        />
      )}

      <div>
        <Typography
          color="text.primary"
          display="inline"
          style={{ fontWeight: 700 }}
          variant="h4"
        >
          👋 Hey there, {mobileScreen && <br />} I'm{" "}
        </Typography>

        <Typography
          display="inline"
          style={{ fontWeight: 700, color: theme.palette.secondary.main }}
          variant="h4"
        >
          {personalInfo.name}.
        </Typography>

        <Typography
          color="text.primary"
          style={{ fontWeight: 700, marginBottom: theme.spacing(2.5) }}
          variant="h4"
        >
          Nice to meet ya!
        </Typography>

        <Typography
          variant="body1"
          color="gray"
          maxWidth={mobileScreen ? 600 : 700}
          style={{
            textAlign: "justify",
            marginBottom: theme.spacing(1),
            paddingRight: theme.spacing(1),
          }}
        >
          I’m a software engineer specializing in building exceptional digital
          experiences, currently working as a junior software engineer at{" "}
          <Button
            style={{
              textTransform: "none",
              fontWeight: 700,
              fontSize: "0.9rem",
            }}
            href="https://www.caribou.care/"
            target="_blank"
            size="small"
            color="secondary"
          >
            Caribou
          </Button>{" "}
          and building a recruitment application to grow health care staff for
          organizations by more than 10% in less than 90 days.
        </Typography>

        <Typography variant="body1" color="gray">
          Here are a few technologies I’ve been working with recently:
        </Typography>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            maxWidth: "300px",
            color: theme.palette.text.secondary,
            justifyContent: "center",
            columnGap: theme.spacing(2),
          }}
        >
          <ul style={{ listStyleType: "square" }}>
            <li style={{ color: theme.palette.secondary.main }}>
              <Typography style={{ fontSize: "0.9em" }} color="text.secondary">
                React.js
              </Typography>
            </li>
            <li style={{ color: theme.palette.secondary.main }}>
              <Typography style={{ fontSize: "0.9em" }} color="text.secondary">
                Node.js
              </Typography>
            </li>
            <li style={{ color: theme.palette.secondary.main }}>
              <Typography style={{ fontSize: "0.9em" }} color="text.secondary">
                React Native
              </Typography>
            </li>
          </ul>
          <ul style={{ listStyleType: "square" }}>
            <li style={{ color: theme.palette.secondary.main }}>
              <Typography style={{ fontSize: "0.9em" }} color="text.secondary">
                TypeScript
              </Typography>
            </li>
            <li style={{ color: theme.palette.secondary.main }}>
              <Typography style={{ fontSize: "0.9em" }} color="text.secondary">
                GraphQL
              </Typography>
            </li>
            <li style={{ color: theme.palette.secondary.main }}>
              <Typography style={{ fontSize: "0.9em" }} color="text.secondary">
                Swift UI
              </Typography>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
