export const footerCss = `
.waves {
    position: relative;
    width: 100%;
    height: 8vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 80px;
    max-height: 400px;
  }
  /* Animation */
  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  @media (max-width: 768px) {
      .waves {
        height: 40px;
        min-height: 40px;
      }
      .content {
        height: 30vh;
      }
    }
`;
