import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { projectValues } from "../../../app-data/personal-projects";
import { ImageStepper } from "../../image-stepper";

interface ProjectModalProps {
  project: projectValues | null;
  onClose: () => void;
}

export const ProjectModal = ({
  project,
  onClose,
}: ProjectModalProps): JSX.Element => {
  const theme = useTheme();

  const children = () => {
    if (!project) {
      return <LinearProgress />;
    }

    return (
      <>
        <ImageStepper images={project.projectImages ?? []} />
        <DialogTitle>{project.name}</DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            style={{ fontWeight: 700, marginBottom: theme.spacing(1) }}
          >
            About
          </Typography>
          <DialogContentText
            style={{ textAlign: "justify" }}
            id="alert-dialog-slide-description"
            paragraph
          >
            {project.description}
          </DialogContentText>
          <Typography
            style={{ textAlign: "justify" }}
            component="div"
            color="text.primary"
            dangerouslySetInnerHTML={{
              __html: project.extraDescription ?? "",
            }}
          />
        </DialogContent>
        <DialogActions>
          {project.demo && (
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant="outlined"
              href={project.demo ?? ""}
              target="_blank"
            >
              Demo
            </Button>
          )}

          <Button
            style={{ marginRight: theme.spacing(1) }}
            color="secondary"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <div>
      <Dialog open={project !== null} onClose={() => onClose()}>
        {children()}
      </Dialog>
    </div>
  );
};
