import GameOfLifeLogo from "./images/Game_of_life.gif";
import DecidoramaLogo from "./images/decidorama.png";
import MovieioLogo from "./images/movieio.png";

import GameOfLifeImage1 from "./images/game-of-life-1.png";
import GameOfLifeImage2 from "./images/game-of-life-2.gif";

import DecidoramaImage1 from "./images/decidorama-1.png";
import DecidoramaImage2 from "./images/decidorama-2.png";
import DecidoramaImage3 from "./images/decidorama-3.png";
import DecidoramaImage4 from "./images/decidorama-4.gif";
import DecidoramaImage5 from "./images/decidorama-5.png";

export interface projectValues {
  name: string;
  imagePath: string;
  technologies: string[];
  code: string;
  date: string;
  description: string;
  demo: string | null;
  learnMoreDisabled?: boolean;
  projectImages: string[];
  extraDescription?: string;
}

export const personalProjects: projectValues[] = [
  {
    name: "Decidorama",
    imagePath: DecidoramaLogo,
    technologies: ["Swift UI", "Firebase", "Cloud"],
    code: "https://bitbucket.org/code3x/decido/src/master/",
    date: "Jan 2021 – Dec 2021",
    projectImages: [
      DecidoramaImage1,
      DecidoramaImage2,
      DecidoramaImage3,
      DecidoramaImage4,
      DecidoramaImage5,
    ],
    demo: null,
    description: `Collaborated with a team of three to achieve a solution for the
       event management problem for student unions by designing and developing
        an IOS application using Swift UI in an Agile environment utilizing a
         test-driven development approach.`,
    extraDescription: `
    The project’s primary advanced area of computer science is Image Recognition. Decidorama uses
     Apple’s framework for machine learning modules which are already pre-trained with lots of images/data.
      One of the frameworks we will be using is called “Mobile Net V2”
    <a target="_blank" href="https://view.genial.ly/6184371d0a4e140d8fa12667/presentation-copy-2-universe-vibrant-presentation">Read more</a>`,
  },
  {
    name: "Game of Life",
    imagePath: GameOfLifeLogo,
    technologies: ["React.js", "TypeScript", "Material UI"],
    code: "https://gitlab.com/George_K/game-of-life",
    date: "Dec 2021 - Feb 2022",
    projectImages: [GameOfLifeImage1, GameOfLifeImage2],
    demo: "https://vibrant-morse-9d4472.netlify.app/",
    extraDescription: `Every cell interacts with its eight neighbours,
     which are the cells that are horizontally, vertically, or diagonally
      adjacent. At each step in time based on a set of rules.`,
    description: `Basic information about Conway's game of life along with that
       I have developed the game of life using React JS with typescript
        by the use of React hooks, I have also taken advantage of material UI
         for presentation.`,
  },
  {
    name: "Movie.io",
    imagePath: MovieioLogo,
    technologies: ["Cordova", "JavaScript", "SQL"],
    code: "https://github.com/georgekopti/Movie.io",
    date: "Jan 2019 - Apr 2019",
    learnMoreDisabled: true,
    projectImages: [],
    demo: null,
    description:
      "Developed a dynamic and responsive application that connects to google maps and TMDB (The Movie DataBase) API using Cordova framework.",
  },
];
