import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Code } from "@mui/icons-material";
import {
  personalProjects,
  projectValues,
} from "../../app-data/personal-projects";
import { useState } from "react";
import { ProjectModal } from "./project-modal";

export const ProjectsComponent = (): JSX.Element => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [projectOpen, setProjectOpen] = useState<projectValues | null>(null);

  return (
    <div style={{ marginTop: theme.spacing(10) }}>
      <Typography
        color="secondary"
        style={{ textAlign: "center", fontWeight: 700 }}
        variant="h4"
      >
        Projects
      </Typography>

      <ProjectModal
        project={projectOpen}
        onClose={() => setProjectOpen(null)}
      />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          rowGap: theme.spacing(3),
          marginTop: theme.spacing(3),
          paddingRight: mobileScreen ? undefined : "50px",
          paddingLeft: mobileScreen ? undefined : "50px",
        }}
      >
        {personalProjects.map((val) => {
          return (
            <Card
              key={JSON.stringify(val)}
              sx={{ display: "flex" }}
              elevation={3}
            >
              {!mobileScreen && (
                <CardMedia
                  component="img"
                  style={{ maxWidth: 161, padding: "20px" }}
                  image={val.imagePath}
                  alt="Live from space album cover"
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography display="inline" variant="h5">
                    {val.name}
                  </Typography>
                  <Typography
                    display="inline"
                    variant="caption"
                    color="text.secondary"
                    style={{
                      float: "right",
                      fontSize: "1em",
                      fontStyle: "italic",
                    }}
                  >
                    {val.date}
                  </Typography>
                  <Typography
                    style={{ overflow: "hidden", textAlign: "justify" }}
                    height={50}
                    variant="body1"
                    color="text.secondary"
                    component="div"
                  >
                    {val.description}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    component="div"
                  >
                    ...
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{ marginTop: theme.spacing(1) }}
                  >
                    {val.technologies.map((tech) => (
                      <Chip
                        key={tech}
                        label={tech}
                        color="primary"
                        variant="outlined"
                        size="small"
                      />
                    ))}
                  </Stack>
                </CardContent>
                <Box
                  sx={{
                    pl: 2,
                    pb: 2.5,
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<Code />}
                    style={{ marginRight: theme.spacing(1.5) }}
                    href={val.code}
                    target="_blank"
                  >
                    Code
                  </Button>

                  <Button
                    color="secondary"
                    variant={val.learnMoreDisabled ? "contained" : "outlined"}
                    disabled={val.learnMoreDisabled}
                    size="small"
                    onClick={() => setProjectOpen(val)}
                  >
                    Learn more
                  </Button>
                </Box>
              </Box>
            </Card>
          );
        })}
      </div>
    </div>
  );
};
