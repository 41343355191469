import {
  AppBar,
  Button,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { personalInfo } from "../../app-data/personal-info";
import Resume from "../contacts-dial/resume/GeorgeKopti-Resume.pdf";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: theme.shadows[0],
}));

export const ApplicationBar = (): JSX.Element => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <StyledAppBar position="sticky" color="primary">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            component="div"
            style={{
              flexGrow: 1,
              color: theme.palette.common.white,
              fontSize: mobileScreen ? "1.3em" : "1.5em",
            }}
          >
            {personalInfo.name}
          </Typography>

          <Button
            href="#projects"
            size="small"
            style={{
              textTransform: "none",
              fontWeight: 50,
              color: theme.palette.common.white,
              marginRight: theme.spacing(2),
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                flexGrow: 1,
                color: theme.palette.common.white,
                fontSize: mobileScreen ? "1.2em" : "1.5em",
              }}
            >
              Projects
            </Typography>
          </Button>

          <Button
            href="#work-history"
            size="small"
            style={{
              textTransform: "none",
              fontWeight: 50,
              color: theme.palette.common.white,
              marginRight: theme.spacing(2),
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                flexGrow: 1,
                color: theme.palette.common.white,
                fontSize: mobileScreen ? "1.2em" : "1.5em",
              }}
            >
              Work
            </Typography>
          </Button>
          <Button
            href="#education"
            size="small"
            style={{
              textTransform: "none",
              fontWeight: 50,
              marginRight: mobileScreen ? undefined : theme.spacing(2),
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                flexGrow: 1,
                color: theme.palette.common.white,
                fontSize: mobileScreen ? "1.2em" : "1.5em",
              }}
            >
              School
            </Typography>
          </Button>

          {!mobileScreen && (
            <Button
              size="small"
              href={Resume}
              download="George-Kopti-Resume.pdf"
              style={{
                textTransform: "none",
                fontWeight: 50,
              }}
            >
              <Typography
                variant="h6"
                component="div"
                style={{
                  flexGrow: 1,
                  color: theme.palette.secondary.light,
                }}
              >
                Resume
              </Typography>
            </Button>
          )}
        </Toolbar>
      </StyledAppBar>

      <div>
        <svg viewBox="0 70 500 60" preserveAspectRatio="xMinYMin meet">
          <path
            d="M0,80 C140,190 400,0 500,100 L500,00 L0,0 Z"
            style={{ stroke: "none", fill: theme.palette.primary.main }}
          ></path>
        </svg>
      </div>
    </>
  );
};
