import CaribouLogo from "./images/caribou-logo.png";
import ClosingTheGapLogo from "./images/closing-the-gap-logo.png";
import SeridanLogo from "./images/sheridan-logo.jpg";

export interface workHistoryValues {
  companyName: string;
  imagePath: string;
  workDuration: string;
  positionName: string;
  description: string;
  technologies: string[];
}

export const workHistory: workHistoryValues[] = [
  {
    companyName: "Caribou",
    imagePath: CaribouLogo,
    workDuration: "Aug 2020 – PRESENT",
    positionName: "Junior Software Engineer (One CO-OP Term & Part-Time)",
    technologies: ["React.js", "Next.js", "TypeScript", "GraphQL"],
    description:
      "Architected and built front end for three react applications to allow organization employees to get rewarded points for referring employees to a job, a job application website, and an admin dashboard for organizations.",
  },
  {
    companyName: "Closing the Gap",
    imagePath: ClosingTheGapLogo,
    workDuration: "Jan 2020 – Aug 2020",
    positionName: "Software Developer (Two CO-OP Terms)",
    technologies: ["ASP.NET", "C#", "MVC"],
    description:
      "Added booking functionality on the client’s portal, reducing resources spent on admins manually creating appointments, and an API to help Closing the Gap's partners manage appointments for healthcare providers.",
  },
  {
    companyName: "Center for Mobile Innovation (CMI) Cloud DX",
    imagePath: SeridanLogo,
    workDuration: "Apr 2019 – Aug 2019",
    positionName: "Software Developer (Part-Time)",
    technologies: ["Python", "DLIB", "Machine learning"],
    description:
      "Designed and developed a python script that recognizes if two images have the same person in them using dLib, achieving a 99.7% success rate.",
  },
  {
    companyName: "Center for Mobile Innovation (CMI) Eleway Laboratories",
    imagePath: SeridanLogo,
    workDuration: "Sep 2019 – Dec 2019",
    positionName: "Software Developer (Part-Time)",
    technologies: ["React.js", "JavaScript", "node.js"],
    description:
      "Used data structure techniques to implement an undo and redo feature for Eleway’s graph editing application using stacks and queues.",
  },
];
